// src/Dashboard.js
import React from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';

// Register all necessary components
Chart.register(...registerables);

const Dashboard = () => {
  const barData = {
    labels: ['January', 'February', 'March', 'April', 'May'],
    datasets: [
      {
        label: 'Sales',
        data: [65, 59, 80, 81, 56],
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
      },
    ],
  };

  const lineData = {
    labels: ['January', 'February', 'March', 'April', 'May'],
    datasets: [
      {
        label: 'Visitors',
        data: [33, 45, 12, 67, 89],
        borderColor: 'rgba(153, 102, 255, 1)',
        fill: false,
      },
    ],
  };

  return (
    <div>
      <h1>Dashboard</h1>
      <div className="chart-container">
        <div className="chart">
          <h2>Bar Chart</h2>
          <Bar data={barData} />
        </div>
        <div className="chart">
          <h2>Line Chart</h2>
          <Line data={lineData} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;